import React, { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';

import './css/style.css';
import './css/normalize.css';
import './fonts/fontawesome-free-5.15.1-web/css/all.css';
import './fonts/fontawesome-free-5.15.1-web/css/brands.css';
import './fonts/fontawesome-free-5.15.1-web/css/fontawesome.css';
import './fonts/fontawesome-free-5.15.1-web/css/regular.css';
import './fonts/fontawesome-free-5.15.1-web/css/solid.css';
import './fonts/fontawesome-free-5.15.1-web/css/svg-with-js.css';
import './fonts/fontawesome-free-5.15.1-web/css/v4-shims.css';

const Home = lazy(() => import('./page/site/Home'));
const Presse = lazy(() => import('./page/site/Presse'));
const Organisation = lazy(() => import('./page/site/Organisation'));

const Terms = lazy(() => import('./page/policies/Terms'));
const Privacy = lazy(() => import('./page/policies/Privacy'));

const NotFound = lazy(() => import('./page/NotFound'));

function LoadingSuspense() {
    return (
        <div className={"loader"}>
            <div className={"childLoader"}/>
        </div>
    )
}

function App() {
    return (
        <Router>
            <Suspense fallback={<LoadingSuspense />}>
                <Routes>
                    <Route exact path="/" element={<Home />}/>
                    <Route exact path="/presse" element={<Presse />}/>
                    <Route exact path="/organisation" element={<Organisation />}/>

                    <Route exact path="/terms" element={<Terms />}/>
                    <Route exact path="/privacy" element={<Privacy />}/>

                    <Route exact path="/*" element={<NotFound />}/>
                </Routes>
            </Suspense>
        </Router>
    );
}

const rootElement = document.getElementById('root');
const root = ReactDOM.createRoot(rootElement);
root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
